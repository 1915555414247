import React, { useEffect, useState } from 'react';
import { Box, Heading, Select, SimpleGrid, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { auth, db } from './firebase';
import { doc, onSnapshot } from 'firebase/firestore';

const DashboardContent = () => {
  const [callVolumeData, setCallVolumeData] = useState([]);
  const [averageCallDurationData, setAverageCallDurationData] = useState([]);
  const [assistantId, setAssistantId] = useState('');
  const [dateRange, setDateRange] = useState(7);
  const [totalCalls, setTotalCalls] = useState(0);
  const [averageCallDuration, setAverageCallDuration] = useState(0);
  const [timeSaved, setTimeSaved] = useState(0);
  const [moneySaved, setMoneySaved] = useState(0);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, 'customers', user.uid);
      const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
        if (snapshot.exists()) {
          const { botkey } = snapshot.data();
          setAssistantId(botkey);
        }
      });
      return () => unsubscribe();
    }
  }, []);

  useEffect(() => {
    const fetchCallVolume = async () => {
      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VAPI_API_KEY}`,
        },
      };
      try {
        const response = await fetch(
          `https://api.vapi.ai/call?assistantId=${assistantId}`,
          options
        );
        if (!response.ok) {
          throw new Error('API key invalid');
        }
        const data = await response.json();
        const callVolume = calculateCallVolume(data, dateRange);
        setCallVolumeData(callVolume);
        const averageCallDuration = calculateAverageCallDurationPerDay(data, dateRange);
        setAverageCallDurationData(averageCallDuration);
        setTotalCalls(calculateTotalCalls(data, dateRange));
        setAverageCallDuration(calculateAverageCallDuration(data, dateRange));
        setTimeSaved(calculateTimeSaved(calculateTotalCalls(data, dateRange)));
        setMoneySaved(calculateMoneySaved(calculateTotalCalls(data, dateRange)));
      } catch (error) {
        console.error('Error fetching call volume:', error);
      }
    };
    if (assistantId) {
      fetchCallVolume();
    }
  }, [assistantId, dateRange]);

  const calculateCallVolume = (calls, range) => {
    const today = new Date();
    const lastDays = Array.from({ length: range }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date;
    });

    const callVolume = lastDays.map((date) => {
      const callCount = calls.filter((call) => {
        const callDate = new Date(call.createdAt);
        return callDate.toDateString() === date.toDateString();
      }).length;
      const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return { date: formattedDate, callCount };
    });

    return callVolume.reverse();
  };

  const calculateTotalCalls = (calls, range) => {
    const today = new Date();
    const lastDays = new Date(today.getTime() - range * 24 * 60 * 60 * 1000);

    const totalCalls = calls.filter((call) => {
      const callDate = new Date(call.createdAt);
      return callDate >= lastDays && callDate <= today;
    }).length;

    return totalCalls;
  };

  const calculateAverageCallDuration = (calls, range) => {
    const today = new Date();
    const lastDays = new Date(today.getTime() - range * 24 * 60 * 60 * 1000);

    const filteredCalls = calls.filter((call) => {
      const callDate = new Date(call.createdAt);
      return callDate >= lastDays && callDate <= today;
    });

    if (filteredCalls.length === 0) {
      return 0;
    }

    const totalDuration = filteredCalls.reduce((sum, call) => {
      const start = new Date(call.startedAt);
      const end = new Date(call.endedAt);
      const durationInSeconds = Math.floor((end - start) / 1000);
      return sum + durationInSeconds;
    }, 0);

    const averageDuration = Math.floor(totalDuration / filteredCalls.length);
    const minutes = Math.floor(averageDuration / 60);
    const seconds = averageDuration % 60;

    return `${minutes} min ${seconds} sec`;
  };

  const calculateAverageCallDurationPerDay = (calls, range) => {
    const today = new Date();
    const lastDays = Array.from({ length: range }, (_, i) => {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      return date;
    });

    const averageCallDuration = lastDays.map((date) => {
      const filteredCalls = calls.filter((call) => {
        const callDate = new Date(call.createdAt);
        return callDate.toDateString() === date.toDateString();
      });

      if (filteredCalls.length === 0) {
        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
        return { date: formattedDate, averageDuration: 0 };
      }

      const totalDuration = filteredCalls.reduce((sum, call) => {
        const start = new Date(call.startedAt);
        const end = new Date(call.endedAt);
        const durationInSeconds = Math.floor((end - start) / 1000);
        return sum + durationInSeconds;
      }, 0);

      const averageDuration = Math.floor(totalDuration / filteredCalls.length / 60); // Convert to minutes
      const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
      return { date: formattedDate, averageDuration };
    });

    return averageCallDuration.reverse();
  };

  const calculateTimeSaved = (callVolume) => {
    const timePerCall = 8; // Assuming each call saves 8 minutes
    const totalMinutes = callVolume * timePerCall;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} hr ${minutes} min`;
  };

  const calculateMoneySaved = (callVolume) => {
    const moneyPerCall = 5; // Assuming each call saves $5
    const totalAmount = callVolume * moneyPerCall;
    return `$${totalAmount.toFixed(2)}`;
  };

  const handleDateRangeChange = (event) => {
    setDateRange(parseInt(event.target.value));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={6}>
        <Heading as="h2" size="xl" mr={4}>
          Dashboard
        </Heading>
        <Select value={dateRange} onChange={handleDateRangeChange} width="200px" bg="white">
          <option value={7}>Last 7 Days</option>
          <option value={30}>Last 30 Days</option>
        </Select>
      </Box>
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={8} mb={8}>
        <Stat bg="white" p={6} borderRadius="md" boxShadow="md" borderBottomWidth={4} borderBottomColor={"#34a4eb"}>
          <StatLabel>Time Saved</StatLabel>
          <StatNumber>{timeSaved}</StatNumber>
        </Stat>
        <Stat bg="white" p={6} borderRadius="md" boxShadow="md" borderBottomWidth={4} borderBottomColor={"#34eb5b"}>
          <StatLabel>Money Saved</StatLabel>
          <StatNumber>{moneySaved}</StatNumber>
        </Stat>
        <Stat bg="white" p={6} borderRadius="md" boxShadow="md" borderBottomWidth={4} borderBottomColor={"#ebc934"}>
          <StatLabel>Total Calls</StatLabel>
          <StatNumber>{totalCalls}</StatNumber>
        </Stat>
        <Stat bg="white" p={6} borderRadius="md" boxShadow="md" borderBottomWidth={4} borderBottomColor={"#eb3734"}>
          <StatLabel>Average Call Duration</StatLabel>
          <StatNumber>{averageCallDuration}</StatNumber>
        </Stat>
      </SimpleGrid>
      <Box bg="white" p={6} borderRadius="md" boxShadow="md" mb={8}>
        <Heading as="h3" size="lg" mb={4}>
          Call Volume
        </Heading>
        <Box height={400}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={callVolumeData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="callCount" stroke="#8884d8" activeDot={{ r: 8 }} name="Call Count" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
      <Box bg="white" p={6} borderRadius="md" boxShadow="md">
        <Heading as="h3" size="lg" mb={4}>
          Average Call Duration
        </Heading>
        <Box height={400}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={500} height={300} data={averageCallDurationData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis unit=" min" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="averageDuration" stroke="#82ca9d" activeDot={{ r: 8 }} name="Average Duration (minutes)" />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardContent;