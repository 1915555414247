import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Button,
  Input,
  Stack,
  useToast,
  FormControl,
  FormLabel,
  Flex,
  Divider,
  useColorModeValue,
  Image,
  Link,
} from '@chakra-ui/react';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import logo from './logo.png';

const Home = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const toast = useToast();

  const handleLogin = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        console.log('User logged in:', user);
  
        // Check if the user document already exists
        const userDocRef = doc(db, 'customers', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (!userDocSnapshot.exists()) {
          // Create a new document in the 'customers' collection only if it doesn't exist
          await setDoc(doc(db, 'customers', user.uid), {
            email: user.email,
          });
        }
  
        navigate('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Login error:', errorCode, errorMessage);
        toast({
          title: 'Login Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleSignup = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        console.log('User signed up:', user);

        // Create a new document in the 'customers' collection
        await setDoc(doc(db, 'customers', user.uid), {
          email: user.email,
        });

        navigate('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Signup error:', errorCode, errorMessage);
        toast({
          title: 'Signup Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        console.log('User logged in with Google:', user);
  
        // Check if the user document already exists
        const userDocRef = doc(db, 'customers', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);
  
        if (!userDocSnapshot.exists()) {
          // Create a new document in the 'customers' collection if it doesn't exist
          await setDoc(doc(db, 'customers', user.uid), {
            email: user.email,
          });
        }
  
        navigate('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Google login error:', errorCode, errorMessage);
        toast({
          title: 'Google Login Error',
          description: errorMessage,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const inputBgColor = useColorModeValue('white', 'gray.800');
  const googleButtonBgColor = useColorModeValue('white', 'gray.800');
  const googleButtonColor = useColorModeValue('gray.700', 'white');

  return (
    <Flex minHeight="100vh" align="center" justify="center" bg="#F1F6FA">
      <Box borderWidth={1} px={4} width="full" maxWidth="500px" borderRadius={50} textAlign="center" boxShadow="lg" bg="white">
        <Box p={10}>
        <Link href="https://hrznlabs.com" style={{ textDecoration: 'none' }}>
            <Flex align="center" justify="center" mb={6}>
              <Image src={logo} alt="Logo" boxSize="50px" mr={2} />
              <Heading as="h2" fontSize={40} fontFamily="Poppins" fontWeight="900">
                HRZN <span style={{ fontWeight: 400 }}>Labs</span>
              </Heading>
            </Flex>
          </Link>
          <Text fontSize="lg" textAlign="center" mb={8}>
            Please login or sign up to continue
          </Text>
          <Stack spacing={4} direction="column" align="center">
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                bg={inputBgColor}
                borderRadius={10}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                bg={inputBgColor}
                borderRadius={10}
              />
            </FormControl>
            <Button
              size="lg"
              onClick={handleLogin}
              width="full"
              bg="#4355FF"
              color="white"
              _hover={{ bg: '#3744CC' }}
              borderRadius={10}
            >
              Login
            </Button>
            <Button
              size="lg"
              onClick={handleSignup}
              width="full"
              bg="#4355FF"
              color="white"
              _hover={{ bg: '#3744CC' }}
              borderRadius={10}
            >
              Sign Up
            </Button>
            <Divider orientation="horizontal" />
            <Button
              leftIcon={<FaGoogle />}
              size="lg"
              onClick={handleGoogleLogin}
              width="full"
              variant="outline"
              bg={googleButtonBgColor}
              color={googleButtonColor}
              borderRadius={10}
            >
              Login with Google
            </Button>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default Home;