import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Heading,
  Text,
  Spinner,
  VStack,
  Wrap,
  Button,
  Input,
  HStack,
} from "@chakra-ui/react";
import axios from "axios";
import { auth, db } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";

const TextMessages = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [phoneKey, setPhoneKey] = useState("");
  const [contactNumbers, setContactNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [messageInput, setMessageInput] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDocRef = doc(db, "customers", user.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (snapshot) => {
          if (snapshot.exists()) {
            const { phoneKey } = snapshot.data();
            setPhoneKey(phoneKey || "");
            console.log("Phone Key:", phoneKey);
          } else {
            console.log("User document does not exist");
          }
        });
        return () => unsubscribeDoc();
      } else {
        console.log("User is not authenticated");
      }
    });
    return () => unsubscribe();
  }, []);

  const fetchTextMessages = useCallback(async () => {
    if (phoneKey) {
      try {
        const sentMessagesResponse = await axios.get(
          `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
          {
            params: {
              From: phoneKey,
              PageSize: 100,
            },
            auth: {
              username: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
              password: process.env.REACT_APP_TWILIO_AUTH_TOKEN,
            },
          }
        );

        const receivedMessagesResponse = await axios.get(
          `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
          {
            params: {
              To: phoneKey,
              PageSize: 100,
            },
            auth: {
              username: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
              password: process.env.REACT_APP_TWILIO_AUTH_TOKEN,
            },
          }
        );

        const allMessages = [
          ...sentMessagesResponse.data.messages,
          ...receivedMessagesResponse.data.messages,
        ];

        setMessages(allMessages);
        console.log("Fetched Messages:", allMessages);

        const numbers = new Set();
        allMessages.forEach((message) => {
          if (message.from !== phoneKey) {
            numbers.add(message.from);
          }
          if (message.to !== phoneKey) {
            numbers.add(message.to);
          }
        });

        setContactNumbers(Array.from(numbers));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching text messages:", error);
        setLoading(false);
      }
    } else {
      console.log("Phone Key is empty");
      setMessages([]);
      setLoading(false);
    }
  }, [phoneKey]);

  useEffect(() => {
    fetchTextMessages();
  }, [fetchTextMessages]);

  const handleNumberClick = (number) => {
    setSelectedNumber(number);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSendMessage = async () => {
    if (selectedNumber && messageInput) {
      try {
        await axios.post(
          `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
          null,
          {
            params: {
              From: phoneKey,
              To: selectedNumber,
              Body: messageInput,
            },
            auth: {
              username: process.env.REACT_APP_TWILIO_ACCOUNT_SID,
              password: process.env.REACT_APP_TWILIO_AUTH_TOKEN,
            },
          }
        );

        setMessageInput("");
        fetchTextMessages();
      } catch (error) {
        console.error("Error sending text message:", error);
      }
    }
  };

  const filteredMessages = messages.filter((message) => {
    const messageContent = message.body || "";
    return (
      ((message.from === phoneKey && message.to === selectedNumber) ||
        (message.from === selectedNumber && message.to === phoneKey)) &&
      messageContent.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const filteredNumbers = contactNumbers.filter((number) =>
    number.toLowerCase().includes(searchQuery.toLowerCase())
  );
  // Create a ref for the messages container
  const messagesEndRef = useRef(null);

  // Scroll to the bottom whenever filteredMessages changes
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [filteredMessages]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="200px"
      >
        <Spinner size="xl" />
      </Box>
    );
  }
  return (
    <Box
      bg="white"
      p={6}
      borderRadius="md"
      boxShadow="md"
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Heading as="h2" size="xl" mb={6}>
        Text Messages
      </Heading>
      <Box mb={6}>
        <Input
          placeholder="Search messages or numbers"
          value={searchQuery}
          onChange={handleSearchChange}
          mr={2}
          size="sm"
        />
      </Box>
      <Wrap spacing={4} mb={6}>
        {filteredNumbers.map((number) => (
          <Button
            key={number}
            onClick={() => handleNumberClick(number)}
            variant={selectedNumber === number ? "solid" : "outline"}
            colorScheme="blue"
          >
            {number}
          </Button>
        ))}
      </Wrap>
      {selectedNumber ? (
        <Box flex={1} display="flex" flexDirection="column">
          <Box flex={1} overflowY="auto" mb={4}>
            <VStack spacing={4} align="stretch" ref={messagesEndRef}>
              {filteredMessages
                .slice()
                .reverse()
                .map((message, index) => (
                  <Box
                    key={message.sid}
                    p={4}
                    bg={message.from === phoneKey ? "blue.100" : "gray.100"}
                    borderRadius="md"
                    alignSelf={
                      message.from === phoneKey ? "flex-end" : "flex-start"
                    }
                    maxWidth="80%"
                  >
                    <Text>{message.body}</Text>
                    <Text fontSize="sm" color="gray.500" mt={2}>
                      {new Date(message.date_sent).toLocaleString()}
                    </Text>
                    {index === 0 && message.from !== phoneKey && (
                      <Text fontSize="sm" color="gray.500" mt={2}>
                        Last message from {message.from}
                      </Text>
                    )}
                  </Box>
                ))}
            </VStack>
          </Box>
          <Box bg="white" p={4} borderTopWidth={1} borderTopColor="gray.200">
            <HStack>
              <Input
                placeholder="Type a message..."
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
              />
              <Button colorScheme="blue" onClick={handleSendMessage}>
                Send
              </Button>
            </HStack>
          </Box>
        </Box>
      ) : (
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text>Select a contact number to view the chat log.</Text>
        </Box>
      )}
    </Box>
  );
};

export default TextMessages;
