// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCo5Fl42k4RKjjyGlkJ6a25H6DikUtfJF0",
  authDomain: "hrzn-labs.firebaseapp.com",
  projectId: "hrzn-labs",
  storageBucket: "hrzn-labs.appspot.com",
  messagingSenderId: "703244492374",
  appId: "1:703244492374:web:ee3eed286a20283ed065f7",
  measurementId: "G-E22EC6HRSK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };