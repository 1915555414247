import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, Input, Button, Stack, useToast, FormControl, FormLabel, Flex, IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { signOut, updateProfile } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { DeleteIcon } from '@chakra-ui/icons';

const Settings = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [botKeys, setBotKeys] = useState([]);
  const [newBotKeyName, setNewBotKeyName] = useState('');
  const [newBotKey, setNewBotKey] = useState('');
  const [newPhoneKey, setNewPhoneKey] = useState('');

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        setName(currentUser.displayName || '');
        setEmail(currentUser.email || '');

        const userDocRef = doc(db, 'customers', currentUser.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const { botKeys } = userDocSnapshot.data();
          setBotKeys(botKeys || []);
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleSave = async () => {
    if (user) {
      try {
        await updateProfile(user, { displayName: name });

        const userDocRef = doc(db, 'customers', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        await setDoc(userDocRef, {
          ...userDocSnapshot.data(),
          botKeys,
          customerId: 'empty',
        });

        toast({
          title: 'Settings Saved',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error saving settings:', error);
        toast({
          title: 'Error Saving Settings',
          description: 'An error occurred while saving the settings.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const handleAddBotKey = () => {
    if (newBotKeyName && newBotKey && newPhoneKey) {
      setBotKeys([...botKeys, { name: newBotKeyName, key: newBotKey, phoneKey: newPhoneKey }]);
      setNewBotKeyName(' ');
      setNewBotKey(' ');
      setNewPhoneKey(' ');
    }
  };

  const handleDeleteBotKey = (index) => {
    const updatedBotKeys = [...botKeys];
    updatedBotKeys.splice(index, 1);
    setBotKeys(updatedBotKeys);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
        toast({
          title: 'Logout Successful',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error('Logout error:', error);
        toast({
          title: 'Logout Error',
          description: 'An error occurred while logging out.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box>
      <Heading as="h2" size="xl" mb={6}>
        Settings
      </Heading>
      {user && (
        <Stack spacing={6} maxW={700}>
          <FormControl maxW={200}>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              size="sm"
              bg="white"
              borderRadius={10}
            />
          </FormControl>
          <FormControl maxW={500}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="sm"
              bg="white"
              borderRadius={10}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Bot Keys</FormLabel>
            <Stack spacing={2}>
              {botKeys.map((botKey, index) => (
                <Flex key={index} align="center">
                  <Input
                    type="text"
                    value={`${botKey.name}: ${botKey.key}`}
                    isReadOnly
                    size="sm"
                    bg="white"
                    borderRadius={10}
                    mr={2}
                    cursor="not-allowed"
                    opacity={0.7}
                  />
                  <Input
                    type="text"
                    value={botKey.phoneKey}
                    isReadOnly
                    size="sm"
                    bg="white"
                    borderRadius={10}
                    mr={2}
                    cursor="not-allowed"
                    opacity={0.7}
                  />
                  <IconButton
                    icon={<DeleteIcon />}
                    size="sm"
                    onClick={() => handleDeleteBotKey(index)}
                    aria-label="Delete Bot Key"
                    colorScheme="red"
                    borderRadius={10}
                  />
                </Flex>
              ))}
              <Flex>
                <Input
                  type="text"
                  placeholder="Bot Key Name"
                  value={newBotKeyName}
                  onChange={(e) => setNewBotKeyName(e.target.value)}
                  size="sm"
                  bg="white"
                  borderRadius={10}
                  mr={2}
                />
                <Input
                  type="text"
                  placeholder="Bot Key"
                  value={newBotKey}
                  onChange={(e) => setNewBotKey(e.target.value)}
                  size="sm"
                  bg="white"
                  borderRadius={10}
                  mr={2}
                />
                <Input
                  type="text"
                  placeholder="Phone Key"
                  value={newPhoneKey}
                  onChange={(e) => setNewPhoneKey(e.target.value)}
                  size="sm"
                  bg="white"
                  borderRadius={10}
                  mr={2}
                />
                <Button
                  colorScheme="blue"
                  onClick={handleAddBotKey}
                  size="sm"
                  borderRadius={10}
                  px="5"
                  bg="#4355FF"
                  _hover={{ bg: '#3744CC' }}
                >
                  Add
                </Button>
              </Flex>
            </Stack>
          </FormControl>
          <Button
            colorScheme="blue"
            onClick={handleSave}
            size="sm"
            borderRadius={10}
            bg="#4355FF"
            _hover={{ bg: '#3744CC' }}
          >
            Save
          </Button>
          <Button colorScheme="red" onClick={handleLogout} size="sm" borderRadius={10}>
            Logout
          </Button>
        </Stack>
      )}
      {!user && <Text>Please login to access the settings.</Text>}
    </Box>
  );
};

export default Settings;