import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Button, Image, Select, Text } from '@chakra-ui/react';
import { FiHome, FiPhone, FiSettings, FiMessageSquare } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './firebase';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import DashboardContent from './DashboardContent';
import Calls from './Calls';
import Settings from './Settings';
import logo from './logo.png';
import TextMessages from './TextMessages';

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [botKeys, setBotKeys] = useState([]);
  const [selectedBotKey, setSelectedBotKey] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate('/');
      } else {
        const userDocRef = doc(db, 'customers', user.uid);
        const unsubscribeDoc = onSnapshot(userDocRef, (snapshot) => {
          if (snapshot.exists()) {
            const { botKeys, botkey } = snapshot.data();
            setBotKeys(botKeys || []);
            setSelectedBotKey(botkey || '');
          }
        });

        return () => unsubscribeDoc();
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleBotKeyChange = async (event) => {
    const selectedBot = botKeys.find((bot) => bot.key === event.target.value);
    if (selectedBot) {
      setSelectedBotKey(selectedBot.key);
  
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, 'customers', user.uid);
        await updateDoc(userDocRef, {
          botkey: selectedBot.key,
          phoneKey: selectedBot.phoneKey,
        });
      }
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        return <DashboardContent />;
      case 'calls':
        return <Calls />;
      case 'sms':
        return <TextMessages />;
      case 'settings':
        return <Settings />;
      default:
        return null;
    }
  };

  return (
    <Box display="flex" minHeight="100vh">
      <Box w="250px" bg="white" p={6} position="fixed" left={0} top={0} bottom={0}>
        <VStack spacing={5} align="stretch">
          <Box display="flex" alignItems="center" >
            <Image src={logo} alt="Logo" boxSize="30px" mr={2} />
            <Heading as="h2" fontSize={28} fontFamily="Poppins" fontWeight="700">
              HRZN <span style={{ fontWeight: 400 }}>Labs</span>
            </Heading>
          </Box>
          <Text mb={-3}>Select your phone bot:</Text>
          <Select
            value={selectedBotKey}
            onChange={handleBotKeyChange}
            placeholder="Select Bot Key"
          >
            {botKeys.map((botKey) => (
              <option key={botKey.key} value={botKey.key}>
                {botKey.name}
              </option>
            ))}
          </Select>
          <Button
            leftIcon={<FiHome />}
            bg={activeTab === 'dashboard' ? '#4355FF' : 'white'}
            color={activeTab === 'dashboard' ? 'white' : 'gray.600'}
            _hover={{ bg: activeTab === 'dashboard' ? '#3A4CD9' : 'gray.200' }}
            onClick={() => setActiveTab('dashboard')}
          >
            Dashboard
          </Button>
          <Button
            leftIcon={<FiPhone />}
            bg={activeTab === 'calls' ? '#4355FF' : 'white'}
            color={activeTab === 'calls' ? 'white' : 'gray.600'}
            _hover={{ bg: activeTab === 'calls' ? '#3A4CD9' : 'gray.200' }}
            onClick={() => setActiveTab('calls')}
          >
            Call Logs
          </Button>
          <Button
            leftIcon={<FiMessageSquare />}
            bg={activeTab === 'sms' ? '#4355FF' : 'white'}
            color={activeTab === 'sms' ? 'white' : 'gray.600'}
            _hover={{ bg: activeTab === 'sms' ? '#3A4CD9' : 'gray.200' }}
            onClick={() => setActiveTab('sms')}
          >
            SMS
          </Button>
          <Button
            leftIcon={<FiSettings />}
            bg={activeTab === 'settings' ? '#4355FF' : 'white'}
            color={activeTab === 'settings' ? 'white' : 'gray.600'}
            _hover={{ bg: activeTab === 'settings' ? '#3A4CD9' : 'gray.200' }}
            onClick={() => setActiveTab('settings')}
          >
            Settings
          </Button>
        </VStack>
      </Box>
      <Box flex={1} p={6} ml="250px" bg="#F1F6FA">
        {renderContent()}
      </Box>
    </Box>
  );
};

export default Dashboard;